import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Title.module.scss';

const Title = ({ className, children }) => (
  <h2 className={cn(style.container, className)}>
    {children}
  </h2>
);

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Title.defaultProps = {
  className: null
};

export default Title;
