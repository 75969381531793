import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-grid-system';

import Title from '../../components/Title';
import SongStoryCarousel from '../../components/SongStoryCarousel';

import style from './SongStory.module.scss';

const SongStory = ({
  titleOutline,
  titleSolid,
  slides
}) => (
  <div className={style.container}>
    <Container>
      <Title className={style.title}>
        <span className={style.outlineText}>
          {titleOutline}
        </span>

        {` ${titleSolid}`}
      </Title>

      <SongStoryCarousel slides={slides} />
    </Container>
  </div>
);

SongStory.propTypes = {
  titleOutline: PropTypes.string.isRequired,
  titleSolid: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired
};

export default SongStory;
