import React, { PureComponent } from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import { register, setPhoto } from '../../../api';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import FileField from '../../../components/FileField';
import SelectField from '../../../components/SelectField';
import { PhoneField, TextArea, TextField } from '../../../components/Fields';
import ErrorMessage from '../../../components/ErrorMessage';
import {
  nonEmptyArrayCheck,
  nonEmptyCheck,
  emailCheck,
  cyrillicCheck
} from '../../../utils/validate';
import { scrollToElement } from '../../../utils';

import style from './RegistrationForm.module.scss';

const requiredField = 'обязательное поле';
const cyrillicField = 'только кириллические символы';

const validators = {
  roles: {
    message: requiredField,
    check: nonEmptyArrayCheck
  },
  firstName: [
    {
      message: requiredField,
      check: nonEmptyCheck
    },
    {
      message: cyrillicField,
      check: cyrillicCheck
    }
  ],
  lastName: [
    {
      message: requiredField,
      check: nonEmptyCheck
    },
    {
      message: cyrillicField,
      check: cyrillicCheck
    }
  ],
  age: {
    message: requiredField,
    check: nonEmptyCheck
  },
  photo: {
    message: requiredField,
    check: nonEmptyCheck
  },
  phone: {
    message: requiredField,
    check: nonEmptyCheck
  },
  email: {
    message: requiredField,
    check: emailCheck
  },
  city: {
    message: requiredField,
    check: nonEmptyCheck
  },
  bestWork: {
    message: requiredField,
    check: nonEmptyCheck
  },
  bestWorkRole: {
    message: requiredField,
    check: nonEmptyCheck
  },
  about: {
    message: requiredField,
    check: nonEmptyCheck
  },
  portfolio: {
    message: requiredField,
    check: nonEmptyCheck
  }
};

const Success = () => (
  <div className={style.success}>
    <h3 className={style.successTitle}>
      Спасибо за заявку!
    </h3>

    <p className={style.successMessage}>
      Мы ответим вам в 2021 году
    </p>
  </div>
);

const defaultState = {
  fetching: false,
  success: null,
  errors: {},

  uid: '',
  roles: [],
  firstName: '',
  lastName: '',
  age: '',
  phone: '',
  email: '',
  city: '',
  bestWork: '',
  bestWorkRole: '',
  about: '',
  portfolio: '',
  trackLanguage: '',
  social: '',
  vkontakteUrl: '',
  instagramUrl: '',
  photo: null,
  team: null,
  canCreateVocals: false,
  isReadyToTravel: false,
  isFamiliarWithCopyrightLaw: false,
  isAvailableForCollaboration: false,
  isAgreeForEverysongExclusives: false,
  isReadyToEntranceFee: false
};

class RegistrationForm extends PureComponent {
  state = { ...defaultState };

  validate = () => Object.keys(validators).reduce((errors, field) => {
    const value = this.state[field];

    const rules = Array.isArray(validators[field])
      ? validators[field]
      : [validators[field]];

    const rule = rules.find(r => !r.check(value));

    if (rule) {
      return {
        ...errors,
        [field]: rule.message
      };
    }

    return errors;
  }, {});

  validateField = name => this.setState((state) => {
    const errors = { ...state.errors };
    const value = state[name];
    const fieldValidator = validators[name];

    if (!fieldValidator) {
      return null;
    }

    const rules = Array.isArray(validators[name])
      ? validators[name]
      : [validators[name]];

    const rule = rules.find(r => !r.check(value));

    if (rule) {
      errors[name] = rule.message;
    } else {
      delete errors[name];
    }

    return { errors };
  });

  onFocus = ({ target: { name } }) => this.setState((state) => {
    const errors = { ...state.errors };
    delete errors[name];
    this.setState({ errors });
  });

  onBlur = (event) => {
    this.validateField(event.target.name);
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.state.fetching) {
      return;
    }

    const validationErrors = this.validate();
    this.setState({ errors: validationErrors });

    if (Object.keys(validationErrors).length) {
      return;
    }

    this.setState({ fetching: true });

    const data = {
      roles: this.state.roles,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      age: this.state.age,
      phone: this.state.phone,
      email: this.state.email,
      city: this.state.city,
      bestWork: this.state.bestWork,
      bestWorkRole: this.state.bestWorkRole,
      about: this.state.about,
      portfolio: this.state.portfolio,
      trackLanguage: this.state.trackLanguage,
      social: this.state.social,
      vkontakteUrl: this.state.vkontakteUrl,
      instagramUrl: this.state.instagramUrl,
      team: this.state.team,
      canCreateVocals: this.state.canCreateVocals,
      isReadyToTravel: this.state.isReadyToTravel,
      isFamiliarWithCopyrightLaw: this.state.isFamiliarWithCopyrightLaw,
      isAvailableForCollaboration: this.state.isAvailableForCollaboration,
      isAgreeForEverysongExclusives: this.state.isAgreeForEverysongExclusives,
      isReadyToEntranceFee: this.state.isReadyToEntranceFee
    };

    register(data)
      .then(({ uid }) => this.setState({
        uid,
        fetching: false
      }, this.sendPhoto))
      .catch(errors => this.setState({
        fetching: false,
        errors: { ...errors }
      }));
  };

  sendPhoto = () => {
    const { uid, photo } = this.state;

    this.setState({
      fetching: true
    });

    return setPhoto(uid, photo)
      .then(() => this.setState(state => ({
        ...defaultState,
        success: state.email
      }), this.onSuccess))
      .catch(errors => this.setState({
        fetching: false,
        errors: { ...errors }
      }));
  };

  onSuccess = () => setTimeout(() => {
    scrollToElement('block-registration');
  }, 100);

  onRoleChange = role => this.setState((state) => {
    const roles = [...state.roles];
    const index = roles.indexOf(role);

    if (index > -1) {
      roles.splice(index, 1);
    } else {
      roles.push(role);
    }

    return { roles };
  }, () => {
    this.validateField('roles');
  });

  onTeamSelect = value => this.setState(state => ({
    ...state,
    team: value
  }));

  onTextFieldChange = ({ target }) => this.setState({
    [target.name]: target.value
  });

  onCheckboxFieldChange = ({ target }) => this.setState({
    [target.name]: !!target.checked
  });

  onFileDrop = ([photo]) => this.setState({
    photo
  }, () => {
    this.validateField('photo');
  });

  render() {
    const {
      fetching,
      success,
      errors,

      roles,
      firstName,
      lastName,
      age,
      phone,
      email,
      city,
      bestWork,
      bestWorkRole,
      about,
      portfolio,
      trackLanguage,
      social,
      vkontakteUrl,
      instagramUrl,
      photo,
      team,
      canCreateVocals,
      isReadyToTravel,
      isFamiliarWithCopyrightLaw,
      isAvailableForCollaboration,
      isAgreeForEverysongExclusives,
      isReadyToEntranceFee
    } = this.state;

    const {
      roleOptions,
      teamOptions
    } = this.props;

    if (success) {
      return <Success />;
    }

    return (
      <div className={style.container}>
        <form onSubmit={this.onSubmit}>
          <fieldset className={style.section}>
            <legend className={style.legend}>
              Выберите род деятельности
            </legend>

            <Row>
              {roleOptions.map(item => (
                <Col
                  key={item.id}
                  xs={12}
                  sm={6}
                  className={style.checkbox}
                >
                  <Checkbox
                    name={`role-${item.id}`}
                    checked={roles.indexOf(item.id) > -1}
                    onChange={() => this.onRoleChange(item.id)}
                  >
                    {item.name}
                  </Checkbox>
                </Col>
              ))}
            </Row>

            {(!!errors.roles) && (
              <ErrorMessage>
                {errors.roles}
              </ErrorMessage>
            )}
          </fieldset>

          <fieldset className={style.section}>
            <legend className={style.legend}>
              Выберите желаемую команду
            </legend>

            <SelectField
              error={errors.team}
              options={teamOptions}
              name="team"
              value={team}
              onChange={this.onTeamSelect}
              className={style.column}
            />
          </fieldset>

          <fieldset className={style.section}>
            <legend className={style.legend}>
              Личная информация
            </legend>

            <Row>
              <Col
                xs={12}
                sm={6}
              >
                <TextField
                  error={errors.lastName}
                  name="lastName"
                  placeholder="Фамилия"
                  value={lastName}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  onChange={this.onTextFieldChange}
                  className={style.column}
                />

                <TextField
                  error={errors.firstName}
                  name="firstName"
                  placeholder="Имя"
                  value={firstName}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  onChange={this.onTextFieldChange}
                  className={style.column}
                />

                <TextField
                  error={errors.age}
                  mask="n9"
                  maskChar=" "
                  formatChars={{ n: '[1-9]', 9: '[0-9]' }}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  name="age"
                  placeholder="Возраст"
                  value={age}
                  onChange={this.onTextFieldChange}
                  className={style.column}
                />
              </Col>

              <Col
                xs={12}
                sm={6}
                className={style.column}
              >
                <FileField
                  error={errors.photo}
                  accept={['image/jpeg', 'image/png']}
                  label={photo ? photo.name : 'Загрузить фото'}
                  onDrop={this.onFileDrop}
                />
              </Col>
            </Row>
          </fieldset>

          <fieldset className={style.section}>
            <legend className={style.legend}>
              Контактная информация
            </legend>

            <Row>
              <Col
                xs={12}
                sm={6}
                className={style.column}
              >
                <PhoneField
                  error={errors.phone}
                  name="phone"
                  placeholder="Телефон"
                  value={phone}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  onChange={this.onTextFieldChange}
                />
              </Col>

              <Col
                xs={12}
                sm={6}
                className={style.column}
              >
                <TextField
                  error={errors.email}
                  name="email"
                  placeholder="Электронная почта"
                  value={email}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  onChange={this.onTextFieldChange}
                />
              </Col>

              <Col
                xs={12}
                sm={6}
                className={style.column}
              >
                <TextField
                  error={errors.city}
                  name="city"
                  placeholder="Город"
                  value={city}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  onChange={this.onTextFieldChange}
                />
              </Col>
            </Row>
          </fieldset>

          <fieldset className={style.section}>
            <legend className={style.legend}>
              Ваша лучшая песня
            </legend>

            <p className={style.notice}>
              Фрагмент данной песни будет доступен для прослушивания всеми участниками кемпа на сайте
            </p>

            <div className={style.column}>
              <TextField
                error={errors.bestWork}
                name="bestWork"
                placeholder="Ссылка или название"
                value={bestWork}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>

            <p className={style.notice}>
              <span className={style.red}>Важно!</span>{' '}
              Подробно опишите суть вашей работы в композиции, от этого зависит ваше участие в CWW.
            </p>

            <div className={style.column}>
              <TextField
                error={errors.bestWorkRole}
                name="bestWorkRole"
                placeholder="Ваша роль"
                value={bestWorkRole}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>
          </fieldset>

          <fieldset className={style.section}>
            <legend className={style.legend}>
              Расскажите о себе
            </legend>

            <div className={style.column}>
              <TextArea
                error={errors.about}
                name="about"
                placeholder="Расскажите о своей деятельности"
                value={about}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>

            <div className={style.column}>
              <TextArea
                error={errors.trackLanguage}
                name="trackLanguage"
                placeholder="Языки, на которых вы сочиняете треки"
                value={trackLanguage}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>

            <div className={style.column}>
              <TextArea
                error={errors.portfolio}
                name="portfolio"
                placeholder="Ссылки на работы (ваше портфолио)"
                value={portfolio}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>

            <div className={style.column}>
              <TextField
                error={errors.vkontakteUrl}
                name="vkontakteUrl"
                placeholder="Ссылка на профиль Вконтакте"
                value={vkontakteUrl}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>

            <div className={style.column}>
              <TextField
                error={errors.instagramUrl}
                name="instagramUrl"
                placeholder="Ссылка на профиль Instagram"
                value={instagramUrl}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>

            <div className={style.column}>
              <TextArea
                error={errors.social}
                name="social"
                placeholder="Ссылки на другие соцсети"
                value={social}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onTextFieldChange}
              />
            </div>
          </fieldset>

          <fieldset>
            <div className={style.column}>
              <Checkbox
                name="canCreateVocals"
                checked={canCreateVocals}
                onChange={this.onCheckboxFieldChange}
              >
                Вы&nbsp;умеете петь и&nbsp;сможете записать вокальную партию на&nbsp;кэмпе
              </Checkbox>
            </div>

            <div className={style.column}>
              <Checkbox
                name="isReadyToTravel"
                checked={isReadyToTravel}
                onChange={this.onCheckboxFieldChange}
              >
                Вы&nbsp;готовы добираться на&nbsp;кэмп в&nbsp;Московскую область своим ходом
              </Checkbox>
            </div>

            <div className={style.column}>
              <Checkbox
                name="isFamiliarWithCopyrightLaw"
                checked={isFamiliarWithCopyrightLaw}
                onChange={this.onCheckboxFieldChange}
              >
                Вы&nbsp;понимаете как работают сонграйтеры. Вы&nbsp;знаете, как делятся авторские, смежные и&nbsp;исполнительские права.
              </Checkbox>
            </div>

            <div className={style.column}>
              <Checkbox
                name="isAvailableForCollaboration"
                checked={isAvailableForCollaboration}
                onChange={this.onCheckboxFieldChange}
              >
                Вы&nbsp;будете готовы издавать написанные треки совместно с&nbsp;другими авторами.
              </Checkbox>
            </div>

            <div className={style.column}>
              <Checkbox
                name="isAgreeForEverysongExclusives"
                checked={isAgreeForEverysongExclusives}
                onChange={this.onCheckboxFieldChange}
              >
                Вы&nbsp;понимаете, что часть написанных песен будет выставлена на&nbsp;эксклюзивную продажу на&nbsp;Everysong
              </Checkbox>
            </div>

            <div className={style.column}>
              <Checkbox
                name="isReadyToEntranceFee"
                checked={isReadyToEntranceFee}
                onChange={this.onCheckboxFieldChange}
              >
                Вы&nbsp;готовы вносить оргвзнос 10&nbsp;000 рублей
              </Checkbox>
            </div>
          </fieldset>

          <p className={style.agreement}>
            {'Нажимая на кнопку, вы даете согласие на '}
            <a href="#">обработку персональных данных</a>
            {' и соглашаетесь с '}
            <a href="#">политикой конфиденциальности</a>
          </p>

          <Button
            block
            disabled={fetching}
            buttonStyle="solidRed"
            type="submit"
          >
            Участвовать
          </Button>

          {(!!errors.nonFieldError) && (
            <ErrorMessage>
              {errors.nonFieldError}
            </ErrorMessage>
          )}
        </form>
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  roleOptions: PropTypes.array.isRequired,
  teamOptions: PropTypes.array.isRequired
};

export default RegistrationForm;
