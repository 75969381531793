import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { ScreenClassRender } from 'react-grid-system';
import { Carousel } from 'react-responsive-carousel';

import style from './CampsCarousel.module.scss';

const CampImage = ({ image, title }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxHeight: 1240) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = data.allImageSharp.edges.find(edge => (
        edge.node.fluid.originalName === image
      ));

      if (!img) {
        return null;
      }

      return (
        <div className={style.camp}>
          <div className={style.imageHolder}>
            <Img fluid={img.node.fluid} />
          </div>

          <div className={style.campTitle}>
            {title}
          </div>
        </div>
      );
    }}
  />
);

CampImage.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const CampsCarousel = ({ camps }) => (
  <ScreenClassRender
    render={classSize => (
      <Carousel
        emulateTouch
        className={style.carousel}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        showArrows={classSize !== 'xs'}
        centerMode={classSize === 'xs'}
        centerSlidePercentage={(classSize === 'xs') ? 80 : null}
      >
        {camps.map(camp => (
          <div
            key={camp.image}
            className={style.slide}
          >
            <CampImage
              image={camp.image}
              title={camp.title}
            />
          </div>
        ))}
      </Carousel>
    )}
  />
);

CampsCarousel.propTypes = {
  camps: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired
};

export default CampsCarousel;
