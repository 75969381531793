import React from 'react';
import {
  Row,
  Col,
  Container
} from 'react-grid-system';
import cn from 'classnames';

import Title from '../../components/Title';
import {
  EverysongLogoImage,
  PoStudiyamLogoImage
} from '../../components/Images';

import style from './Organizers.module.scss';

export default () => (
  <div
    id="block-organizers"
    className={style.container}
  >
    <Container>
      <div className={style.block}>
        <div className={style.inner}>
          <Title>
            Организаторы
          </Title>

          <Row
            className={style.organizers}
            justify="center"
            align="center"
          >
            <Col xs={12} sm={6} lg={4} xl={3}>
              <div className={cn(style.image, style.mmusic)}>
                <a
                  href="https://max-song.ru"
                  target="_blank" // eslint-disable-line react/jsx-no-target-blank
                >
                  <EverysongLogoImage />
                </a>
              </div>
            </Col>

            <Col xs={12} sm={6} lg={4} xl={3}>
              <div className={cn(style.image, style.poStudiyam)}>
                <PoStudiyamLogoImage />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  </div>
);
