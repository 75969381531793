/* eslint-disable no-unused-vars, quote-props */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

class PhoneField extends PureComponent {
  beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value, selection } = newState;
    let cursorPosition = selection ? selection.start : null;

    if (userInput === '8' && cursorPosition === 1) {
      value = `+7${value.slice(1)}`;

      cursorPosition += 1;

      selection = {
        start: cursorPosition,
        end: cursorPosition
      };
    }

    return { value, selection };
  };

  render() {
    const {
      value,
      onChange,
      ...props
    } = this.props;

    return (
      <TextField
        {...props}
        mask={`+${Array(22).join('9')}`}
        maskChar={null}
        formatChars={{ '9': '[0-9]', '+': '[+0-9]' }}
        value={value}
        onChange={onChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
}

PhoneField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PhoneField;
