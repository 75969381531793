import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import HowItWasTemplate from './HowItWas';

const HowItWas = () => {
  const data = useStaticQuery(graphql`
    query {
      yaml: allDataYaml(filter: { block: { eq: "how-it-was" } }) {
        edges {
          node {
            titleOutline
            titleSolid
            videoId
          }
        }
      }
    }
  `);

  const {
    titleOutline,
    titleSolid,
    videoId
  } = data.yaml.edges[0].node;

  return (
    <HowItWasTemplate
      titleOutline={titleOutline}
      titleSolid={titleSolid}
      videoId={videoId}
    />
  );
};

export default HowItWas;
