import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import PromoVideoTemplate from './PromoVideo';

const PromoVideo = () => {
  const data = useStaticQuery(graphql`
    query {
      preview: file(relativePath: { eq: "promo-video.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yaml: allDataYaml(filter: { block: { eq: "promo-video" } }) {
        edges {
          node {
            videoId
          }
        }
      }
    }
  `);

  return (
    <PromoVideoTemplate
      preview={data.preview.childImageSharp.fluid}
      videoId={data.yaml.edges[0].node.videoId}
    />
  );
};

export default PromoVideo;
