import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Ticker from 'react-ticker';

import style from './TagsTicker.module.scss';

const TagsList = ({ tags }) => (
  <Fragment>
    {tags.map(tag => (
      <div
        key={tag}
        className={style.tag}
      >
        {tag}
      </div>
    ))}
  </Fragment>
);

TagsList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
};

class TagsTicker extends PureComponent {
  state = {
    initialized: false,
    move: true
  };

  componentDidMount() {
    this.setState({
      initialized: true
    });

    window.addEventListener('blur', this.onWindowBlur, false);
    window.addEventListener('focus', this.onWindowFocus, false);
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.onWindowBlur, false);
    window.removeEventListener('focus', this.onWindowFocus, false);
  }

  onWindowBlur = () => this.setState({ move: false });

  onWindowFocus = () => this.setState({ move: true });

  render() {
    const { initialized, move } = this.state;

    return (
      <div className={style.container}>
        <div className={style.center}>
          {initialized && (
            <Ticker move={move}>
              {() => (
                <TagsList {...this.props} />
              )}
            </Ticker>
          )}

          {!initialized && (
            <TagsList {...this.props} />
          )}
        </div>
      </div>
    );
  }
}

export default TagsTicker;
