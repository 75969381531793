import React from 'react';
import { Container } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';

import Title from '../../components/Title';
import CampsCarousel from '../../components/CampsCarousel';

import style from './Camps.module.scss';

const Camps = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml(filter: {block: {eq: "camps"}}) {
        edges {
          node {
            titleOutline
            titleSolid
            camps {
              image
              title
            }
          }
        }
      }
    }
  `);

  const {
    titleOutline,
    titleSolid,
    camps
  } = data.allDataYaml.edges[0].node;

  return (
    <div
      id="block-camps"
      className={style.container}
    >
      <Container>
        <Title className={style.title}>
          <span className={style.outlineText}>
            {titleOutline}
          </span>

          {` ${titleSolid}`}
        </Title>

        <CampsCarousel camps={camps} />
      </Container>
    </div>
  );
};

export default Camps;
