import React from 'react';
import { Container, Visible, Hidden } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';

import Title from '../../components/Title';
import { SpeakersList, SpeakersCarousel } from '../../components/SpeakersList';

import style from './Speakers.module.scss';

const Speakers = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml(filter: {block: {eq: "speakers"}}) {
        edges {
          node {
            titleOutline
            titleSolid
            speakers {
              number
              image
              fullname
              about
              topic
            }
          }
        }
      }
    }
  `);

  const {
    titleOutline,
    titleSolid,
    speakers
  } = data.allDataYaml.edges[0].node;

  return (
    <div
      id="block-speakers"
      className={style.container}
    >
      <Container>
        <Title className={style.title}>
          <span className={style.outlineText}>{titleOutline}</span>
          {` ${titleSolid}`}
        </Title>

        <Visible xs>
          <SpeakersCarousel
            speakers={speakers}
          />
        </Visible>

        <Hidden xs>
          <SpeakersList
            speakers={speakers}
          />
        </Hidden>
      </Container>
    </div>
  );
};

export default Speakers;
