import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './PlayButton.module.scss';

const PlayButton = ({
  className,
  ...props
}) => (
  <button
    className={cn(style.play, className)}
    type="button"
    title="Play"
    aria-label="Play"
    {...props}
  >
    <div className={style.playContent}>
      <svg
        className={style.playBorder}
        width="100%"
        height="100%"
        viewBox="0 0 110 134"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke="#FFF"
          strokeWidth="2"
          d="M107 64.5L1 2v130z"
          fill="none"
          fillRule="evenodd"
        />
      </svg>

      <svg
        className={style.playShape}
        width="100%"
        height="100%"
        viewBox="0 0 110 134"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stopColor="#FF004D" offset="0%" />
            <stop stopColor="#FF001F" offset="100%" />
          </linearGradient>
        </defs>

        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon
            id="Path"
            fill="url(#linearGradient-1)"
            fillRule="nonzero"
            points="110 64.4231 0 0 0 134"
          />
        </g>
      </svg>
    </div>
  </button>
);

PlayButton.propTypes = {
  className: PropTypes.string
};

PlayButton.defaultProps = {
  className: ''
};

export default PlayButton;
