/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import ErrorMessage from '../ErrorMessage';

import style from './styles.module.scss';

const TextField = ({
  className,
  error,
  name,
  mask,
  notice,
  ...otherProps
}) => {
  const inputProps = {
    id: `field-${name}`,
    className: cn(style.input, {
      [style.error]: !!error
    }),
    name,
    type: 'text',
    ...otherProps
  };

  return (
    <div className={cn(style.field, className)}>
      {(!mask) && (
        <input {...inputProps} />
      )}

      {(!!mask) && (
        <InputMask
          mask={mask}
          {...inputProps}
        />
      )}

      {(!!notice) && (
        <p className={style.notice}>
          {notice}
        </p>
      )}

      {(!!error) && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}
    </div>
  );
};

TextField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string,
  notice: PropTypes.string
};

TextField.defaultProps = {
  className: '',
  error: '',
  mask: null,
  notice: null
};

export default TextField;
