import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';

import config from '../../config';
import Title from '../../components/Title';
import Map from '../../components/Map';

import style from './Contacts.module.scss';

const formatPhone = phone => phone.replace(/[^0-9+]/g, '');

const Contacts = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml(filter: {block: {eq: "contacts"}}) {
        edges {
          node {
            person
            phone
            email
            vkUrl
            igUrl
            lat
            lng
            place
            address
          }
        }
      }
    }
  `);

  const {
    person,
    phone,
    email,
    vkUrl,
    igUrl,
    lat,
    lng,
    place,
    address
  } = data.allDataYaml.edges[0].node;

  return (
    <div
      id="block-contacts"
      className={style.container}
    >
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <Title className={style.title}>
              Контакты
            </Title>

            <div className={style.section}>
              <h3 className={style.subtitle}>
                Организаторы
              </h3>

              <div className={style.info}>
                {person},

                <br />

                <a
                  href={`phone:${formatPhone(phone)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.phone}
                >
                  {phone}
                </a>

                <br />

                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.email}
                >
                  {email}
                </a>
              </div>
            </div>

            <div className={style.section}>
              <h3 className={style.subtitle}>
                Социальные сети
              </h3>

              <a
                href={igUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={style.socialLink}
              >
                instagram
              </a>

              <br />

              <a
                href={vkUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={style.socialLink}
              >
                vkontakte
              </a>
            </div>
          </Col>

          <Col sm={12} md={6}>
            <div className={style.map}>
              <Map
                mapKey={config.googleMapsApiKey}
                position={{ lat, lng }}
              />
            </div>

            <p className={style.mapTitle}>
              {place} <br />{address}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contacts;
