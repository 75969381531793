import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Row, Col } from 'react-grid-system';

import style from './PartnersList.module.scss';

const PartnerImage = ({ imgName, url }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxHeight: 90) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find(edge => (
        edge.node.fluid.originalName === imgName
      ));

      if (!image) {
        return null;
      }

      return (
        <a
          href={url}
          className={style.partnerUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Img
            fluid={image.node.fluid}
            className={style.partnerImage}
            style={{
              maxWidth: image.node.fluid.aspectRatio * 90
            }}
          />
        </a>
      );
    }}
  />
);

PartnerImage.propTypes = {
  imgName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

const PartnersList = ({ partners }) => (
  <Row
    align="center"
    className={style.container}
  >
    {partners.map((partner, index) => (
      <Col
        key={partner.image}
        className={style.col}
        xs={3.5}
        sm={2.5}
        offset={{
          sm: index % 4 ? 2 / 3 : 0,
          xs: index % 3 ? 1.5 / 2 : 0
        }}
      >
        <PartnerImage
          url={partner.url}
          imgName={partner.image}
        />
      </Col>
    ))}
  </Row>
);

PartnersList.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })).isRequired
};

export default PartnersList;
