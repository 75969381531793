import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import style from './ErrorMessage.module.scss';

const ErrorMessage = ({ children, className }) => (
  <div className={cn(style.container, className)}>
    {children}
  </div>
);

ErrorMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

ErrorMessage.defaultProps = {
  className: '',
  children: null
};

export default ErrorMessage;
