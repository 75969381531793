import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import NetworkingTemplate from './Networking';

const Networking = () => {
  const data = useStaticQuery(graphql`
    query {
      preview: file(relativePath: { eq: "networking.png" }) {
        childImageSharp {
          fluid(maxWidth: 610) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yaml: allDataYaml(filter: { block: { eq: "networking" } }) {
        edges {
          node {
            videoId
          }
        }
      }
    }
  `);

  return (
    <NetworkingTemplate
      preview={data.preview.childImageSharp.fluid}
      videoId={data.yaml.edges[0].node.videoId}
    />
  );
};

export default Networking;
