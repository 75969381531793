import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import Img from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';

import style from './SpeakersList.module.scss';

const SpeakerImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const name = imgName || 'noise.png';

      const image = data.allImageSharp.edges.find(edge => (
        edge.node.fluid.originalName === name
      ));

      if (!image) {
        return null;
      }

      return (
        <Img fluid={image.node.fluid} />
      );
    }}
  />
);

SpeakerImage.propTypes = {
  imgName: PropTypes.string
};

SpeakerImage.defaultProps = {
  imgName: null
};

const Speaker = ({
  number,
  image,
  fullname,
  about,
  topic
}) => (
  <div className={style.speaker}>
    <div className={style.imageHolder}>
      <SpeakerImage
        imgName={image}
      />

      {typeof number !== 'undefined' && (
        <div className={style.number}>
          {number}
        </div>
      )}
    </div>

    <h3 className={style.fullname}>
      {fullname}
    </h3>

    {!!about && (
      <p className={style.about}>
        {about}
      </p>
    )}

    {!!topic && (
      <p className={style.topic}>
        {topic}
      </p>
    )}
  </div>
);

Speaker.propTypes = {
  number: PropTypes.string.isRequired,
  image: PropTypes.string,
  fullname: PropTypes.string.isRequired,
  about: PropTypes.string,
  topic: PropTypes.string
};

Speaker.defaultProps = {
  image: null,
  about: null,
  topic: null
};

const SpeakersList = ({ speakers }) => (
  <Row>
    {speakers.map(speaker => (
      <Col
        key={speaker.fullname}
        sm={4}
        md={3}
      >
        <Speaker {...speaker} />
      </Col>
    ))}
  </Row>
);

SpeakersList.propTypes = {
  speakers: PropTypes.arrayOf(PropTypes.shape({
    fullname: PropTypes.string.isRequired
  })).isRequired
};

const SpeakersCarousel = ({ speakers }) => (
  <Carousel
    emulateTouch
    centerMode
    className={style.carousel}
    showThumbs={false}
    showArrows={false}
    showIndicators={false}
    showStatus={false}
    centerSlidePercentage={80}
  >
    {speakers.map(speaker => (
      <div
        key={speaker.fullname}
        className={style.slide}
      >
        <Speaker {...speaker} />
      </div>
    ))}
  </Carousel>
);

SpeakersCarousel.propTypes = {
  speakers: PropTypes.arrayOf(PropTypes.shape({
    fullname: PropTypes.string.isRequired
  })).isRequired
};

export {
  SpeakersList,
  SpeakersCarousel
};
