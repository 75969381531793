import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container, Col, Row, Visible, Hidden
} from 'react-grid-system';

import Modal from '../../components/Modal';
import PlayButton from '../../components/PlayButton';

import style from './Networking.module.scss';

const Networking = ({
  preview,
  videoId
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={style.container}>
      <Container>
        <Row justify="center">
          <Col
            sm={12}
            md={7.5}
            lg={6}
          >
            <div className={style.content}>
              <Visible xs sm>
                <div className={style.title}>
                  Нетворкинг
                </div>
              </Visible>

              <Hidden xs sm>
                <div className={style.bottom}>
                  тренды
                </div>
              </Hidden>

              <div className={style.preview}>
                <Img
                  className={style.image}
                  fluid={preview}
                />

                <PlayButton onClick={() => setOpen(true)} />
              </div>

              <Hidden xs sm>
                <div className={style.top}>
                  Нетворкинг
                </div>

                <div className={style.right}>
                  творчество
                </div>
              </Hidden>
            </div>
          </Col>

          <Hidden xs sm>
            <div className={style.disclaimer}>
              Участвовать могут все
            </div>
          </Hidden>
        </Row>
      </Container>

      {(open) && (
        <Modal
          className={style.modal}
          classNameInner={style.modalInner}
          onRequestClose={() => setOpen(false)}
        >
          <Container>
            <div className={style.modalContent}>
              <iframe // eslint-disable-line jsx-a11y/iframe-has-title
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                height="100%"
                width="100%"
              />
            </div>
          </Container>
        </Modal>
      )}
    </div>
  );
};

Networking.propTypes = {
  preview: PropTypes.object.isRequired,
  videoId: PropTypes.string.isRequired
};

export default Networking;
