/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import ErrorMessage from '../ErrorMessage';

import style from './styles.module.scss';

const TextArea = ({
  className,
  error,
  name,
  notice,
  ...otherProps
}) => {
  const inputProps = {
    id: `field-${name}`,
    className: cn(style.input, style.textarea, {
      [style.error]: !!error
    }),
    name,
    ...otherProps
  };

  return (
    <div className={cn(style.field, className)}>
      <textarea {...inputProps} />

      {(!!notice) && (
        <p className={style.notice}>
          {notice}
        </p>
      )}

      {(!!error) && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  notice: PropTypes.string
};

TextArea.defaultProps = {
  className: '',
  error: '',
  notice: null
};

export default TextArea;
