import React, { PureComponent } from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import PropTypes from 'prop-types';

import mapStyles from './mapStyles.json';

import style from './Map.module.scss';

class MapRenderer extends PureComponent {
  static propTypes = {
    position: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { google } = window;
    const { position } = this.props;

    const map = new google.maps.Map(this.mapNode, {
      mapTypeControl: false,
      zoom: 15,
      gestureHandling: 'greedy',
      backgroundColor: '#f5f5f5',
      center: position,
      styles: mapStyles
    });

    const svg = (`
      <svg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17" cy="17" r="17" fill="url(#paint0_linear)"/>
        <defs>
          <linearGradient id="paint0_linear" x1="17" x2="17" y2="34" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF004D"/>
            <stop offset="1" stop-color="#FF001F"/>
          </linearGradient>
        </defs>
      </svg>
    `);

    new google.maps.Marker({ // eslint-disable-line no-new
      map,
      position: map.getCenter(),
      icon: {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
        fillOpacity: 1,
        scale: 17
      }
    });
  }

  render() {
    return (
      <div
        className={style.container}
        ref={(elem) => { this.mapNode = elem; }}
      />
    );
  }
}

const Map = ({ mapKey, ...props }) => (
  <ReactGoogleMapLoader
    params={{ key: mapKey }}
    render={googleMaps => googleMaps && (
      <MapRenderer {...props} />
    )}
  />
);

Map.propTypes = {
  mapKey: PropTypes.string.isRequired
};

export default Map;
