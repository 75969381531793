import React from 'react';
import { Container } from 'react-grid-system';

import { scrollToElement } from '../../utils';

import style from './SoldOut.module.scss';
import Button from '../../components/Button/Button';

const SoldOut = () => {
  const onClick = (event) => {
    event.preventDefault();
    scrollToElement('block-registration');
  };

  return (
    <div
      id="block-sold-out"
      className={style.container}
    >
      <Container>
        <div className={style.content}>
          <div className={style.notice}>
            <p>Прием заявок на&nbsp;участие в&nbsp;Songwriting&nbsp;Camp&nbsp;&rsquo;20&nbsp;завершен.</p>
            <p>Вы&nbsp;можете подать заявку на&nbsp;участие в&nbsp;команде на&nbsp;2021&nbsp;год.</p>

            <Button
              className={style.button}
              onClick={onClick}
            >
              Участвовать в 2021 году
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SoldOut;
