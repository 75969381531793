import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

import style from './ScheduleAccordion.module.scss';

const DaySchedule = ({ schedule }) => (
  <div className={style.day}>
    {(schedule.map(({ title, time }) => (
      <div
        key={`${title}${time}`}
        className={style.dayTime}
      >
        <div className={style.title}>
          {title}
        </div>

        <div className={style.time}>
          {time}
        </div>
      </div>
    )))}
  </div>
);

DaySchedule.propTypes = {
  schedule: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
  })).isRequired
};

const ScheduleAccordion = ({ list }) => (
  <div className={style.container}>
    {list.map(({ title, date, schedule }) => (
      <Collapsible
        key={date}
        trigger={`${title} / ${date}`}
        easing="ease-in-out"
        className={style.collapsible}
        openedClassName={style.collapsible}
        triggerClassName={style.trigger}
        triggerOpenedClassName={style.triggerOpened}
        contentInnerClassName={style.contentInner}
      >
        <DaySchedule
          schedule={schedule}
        />
      </Collapsible>
    ))}
  </div>
);

ScheduleAccordion.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    schedule: PropTypes.array.isRequired
  })).isRequired
};

export default ScheduleAccordion;
