import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { ScreenClassRender } from 'react-grid-system';
import { Carousel } from 'react-responsive-carousel';

import Title from '../Title';

import style from './SongStoryCarousel.module.scss';

const SongStoryImage = ({ image, date, title }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxHeight: 1240) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = data.allImageSharp.edges.find(edge => (
        edge.node.fluid.originalName === image
      ));

      if (!img) {
        return null;
      }

      return (
        <div className={style.slide}>
          <div className={style.slideImage}>
            <Img fluid={img.node.fluid} />
          </div>

          <div className={style.slideContent}>
            <Title className={style.slideDate}>
              {date}
            </Title>

            <div
              dangerouslySetInnerHTML={{ __html: title }} // eslint-disable-line react/no-danger
              className={style.slideTitle}
            />
          </div>
        </div>
      );
    }}
  />
);

SongStoryImage.propTypes = {
  image: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const SongStoryCarousel = ({ slides }) => (
  <ScreenClassRender
    render={classSize => (
      <Carousel
        emulateTouch
        className={style.carousel}
        showThumbs={false}
        showStatus={false}
        showArrows={classSize !== 'xs'}
        centerMode={classSize === 'xs'}
        centerSlidePercentage={(classSize === 'xs') ? 80 : null}
      >
        {slides.map(slide => (
          <div
            key={slide.image}
            className={style.slideContainer}
          >
            <SongStoryImage
              image={slide.image}
              date={slide.date}
              title={slide.title}
            />
          </div>
        ))}
      </Carousel>
    )}
  />
);

SongStoryCarousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired
};

export default SongStoryCarousel;
