import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ErrorMessage from '../ErrorMessage';

import style from './FileField.module.scss';

const FileField = ({
  className,
  error,
  accept,
  label,
  onDrop
}) => (
  <div className={style.container}>
    <Dropzone
      onDrop={onDrop}
      accept={accept}
    >
      {({
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
        isDragActive
      }) => (
        <div
          {...getRootProps({
            className: cn(style.dropzone, {
              [style.active]: isDragActive,
              [style.reject]: isDragReject,
              [style.accept]: isDragAccept,
              [style.error]: !!error
            }, className),
            onDrop: event => event.preventDefault()
          })}
        >
          <input {...getInputProps()} />

          {label}
        </div>
      )}
    </Dropzone>

    {(!!error) && (
      <ErrorMessage>
        {error}
      </ErrorMessage>
    )}
  </div>
);

FileField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  accept: PropTypes.array,
  label: PropTypes.string,
  onDrop: PropTypes.func.isRequired
};

FileField.defaultProps = {
  className: '',
  error: '',
  accept: [],
  label: ''
};

export default FileField;
