import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-grid-system';

import Title from '../../components/Title';

import style from './HowItWas.module.scss';

const HowItWas = ({
  titleOutline,
  titleSolid,
  videoId
}) => (
  <div className={style.container}>
    <Container>
      <Title className={style.title}>
        <div className={style.outlineText}>
          {titleOutline}
        </div>
        {` ${titleSolid}`}
      </Title>

      <div className={style.wrapper}>
        <div className={style.video}>
          <iframe // eslint-disable-line jsx-a11y/iframe-has-title
            allowFullScreen
            allow="accelerometer;encrypted-media;gyroscope;picture-in-picture"
            frameBorder="0"
            loading="lazy"
            src={`https://www.youtube.com/embed/${videoId}`}
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </Container>
  </div>
);

HowItWas.propTypes = {
  titleOutline: PropTypes.string.isRequired,
  titleSolid: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired
};

export default HowItWas;
