import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';

import Title from '../../components/Title';
import ScheduleAccordion from '../../components/ScheduleAccordion';

import style from './Schedule.module.scss';

const Schedule = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml(filter: {block: {eq: "schedule"}}) {
        edges {
          node {
            titleOutline
            titleSolid
            days {
              date
              title
              schedule {
                time
                title
              }
            }
          }
        }
      }
    }
  `);

  const {
    titleOutline,
    titleSolid,
    days
  } = data.allDataYaml.edges[0].node;

  return (
    <div
      id="block-schedule"
      className={style.container}
    >
      <Container>
        <Row>
          <Col sm={4}>
            <Title className={style.title}>
              <div className={style.outlineText}>
                {titleOutline}
              </div>

              {` ${titleSolid}`}
            </Title>
          </Col>

          <Col sm={8}>
            <ScheduleAccordion
              list={days}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Schedule;
