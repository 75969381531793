import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../utils';

import style from './SelectField.module.scss';

export const getOptionLabelText = option => (
  typeof option.label !== 'undefined'
    ? option.label
    : option.value
);

const SelectField = ({
  id,
  name,
  value,
  options,
  className,
  renderControl,
  renderOption,
  onChange
}) => {
  const selected = options.find(option => option.value === value);
  const onChangeProxy = ({ target }) => {
    const option = options.find(o => `${o.value}` === target.value);
    onChange(option.value, name);
  };

  const renderControlResult = typeof renderControl === 'function'
    ? renderControl
    : val => (val ? getOptionLabelText(val) : 'Не выбрано');

  return (
    <div id={id} className={cn(style.component, className)}>
      {renderControlResult(selected || null)}

      <select
        value={`${value}`}
        onChange={onChangeProxy}
      >
        {options.map(option => (
          <option
            key={option.value}
            value={`${option.value}`}
          >
            {renderOption(option)}
          </option>
        ))}
      </select>

      <svg
        className={style.chevron}
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
      >
        <path
          d="M13 1L7 7 1 1"
          stroke="#fff"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string,
  renderControl: PropTypes.func,
  renderOption: PropTypes.func,
  onChange: PropTypes.func
};

SelectField.defaultProps = {
  id: null,
  name: null,
  options: [],
  value: '',
  className: null,
  renderControl: null,
  renderOption: getOptionLabelText,
  onChange: noop
};

export default memo(SelectField);
