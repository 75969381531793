import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';

import { getAvailableTeams, getRoles } from '../../api';
import Title from '../../components/Title';
import RegistrationForm from './RegistrationForm';

import style from './Registration.module.scss';

const toOptions = (items) => {
  const options = items.map(item => ({
    label: item.name,
    value: item.id
  }));

  return [
    { label: 'Не выбрано', value: null },
    ...options
  ];
};

const Registration = () => {
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    getRoles().then(setRoles);
    getAvailableTeams().then(items => setTeams(toOptions(items)));
  }, []);

  return (
    <div
      id="block-registration"
      className={style.container}
    >
      <Container>
        <Row>
          <Col
            md={12}
            lg={6}
            className={style.head}
          >
            <Title className={style.title}>
              <span className={style.outlineText}>
                Принять
              </span>

              <br />
              участие
            </Title>

            <p className={style.description}>
              Анкета на&nbsp;участие в&nbsp;Songwriting&nbsp;Camp&nbsp;&rsquo;21.
            </p>

            <p className={style.description}>
              Вы&nbsp;можете подать заявку на&nbsp;участие в&nbsp;команде на&nbsp;2021&nbsp;год.
            </p>
          </Col>

          <Col md={12} lg={6}>
            <RegistrationForm
              roleOptions={roles}
              teamOptions={teams}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Registration;
