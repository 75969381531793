import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container } from 'react-grid-system';

import Modal from '../../components/Modal';
import PlayButton from '../../components/PlayButton';

import style from './PromoVideo.module.scss';

const PromoVideo = ({
  preview,
  videoId
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={style.container}>
      <Container>
        <div className={style.preview}>
          <Img
            className={style.image}
            fluid={preview}
          />

          <PlayButton onClick={() => setOpen(true)} />
        </div>
      </Container>

      {(open) && (
        <Modal
          className={style.modal}
          classNameInner={style.modalInner}
          onRequestClose={() => setOpen(false)}
        >
          <Container>
            <div className={style.modalContent}>
              <iframe // eslint-disable-line jsx-a11y/iframe-has-title
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                height="100%"
                width="100%"
              />
            </div>
          </Container>
        </Modal>
      )}
    </div>
  );
};

PromoVideo.propTypes = {
  preview: PropTypes.object.isRequired,
  videoId: PropTypes.string.isRequired
};

export default PromoVideo;
