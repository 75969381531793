import React, { Component, Fragment } from 'react';
import { setConfiguration } from 'react-grid-system';

import SEO from '../components/SEO';
import Footer from '../components/Footer';
import Heading from '../blocks/Heading';
import HowItWas from '../blocks/HowItWas'
import Schedule from '../blocks/Schedule';
import Organizers from '../blocks/Organizers';
import Speakers from '../blocks/Speakers';
import Partners from '../blocks/Partners';
import Camps from '../blocks/Camps';
import Registration from '../blocks/Registration';
import Contacts from '../blocks/Contacts';
import Networking from '../blocks/Networking';
import SongStory from '../blocks/SongStory';
import PromoVideo from '../blocks/PromoVideo';
import SoldOut from '../blocks/SoldOut';

import '../style/index.scss';

class IndexPage extends Component {
  constructor(...args) {
    super(...args);

    setConfiguration({
      breakpoints: [576, 768, 980, 1280],
      containerWidths: [536, 760, 980, 1260],
      gutterWidth: 20
    });
  }

  render() {
    return (
      <Fragment>
        <SEO />

        <Heading />

        <SoldOut />

        <HowItWas />

        <Schedule />

        <PromoVideo />

        <Speakers />

        <SongStory />

        <Partners />

        <Camps />

        <Registration />

        <Contacts />

        <Organizers />

        <Networking />

        <Footer />
      </Fragment>
    );
  }
}

export default IndexPage;
