import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SongStory from './SongStory';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      yaml: allDataYaml(filter: { block: { eq: "song-story" } }) {
        edges {
          node {
            titleOutline
            titleSolid
            slides {
              image
              date
              title
            }
          }
        }
      }
    }
  `);

  const {
    titleOutline,
    titleSolid,
    slides
  } = data.yaml.edges[0].node;

  return (
    <SongStory
      titleOutline={titleOutline}
      titleSolid={titleSolid}
      slides={slides}
    />
  );
};
