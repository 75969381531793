import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';

import Title from '../../components/Title';
import PartnersList from '../../components/PartnersList';

import style from './Partners.module.scss';

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataYaml(filter: {block: {eq: "partners"}}) {
        edges {
          node {
            partners {
              image
              url
            }
          }
        }
      }
    }
  `);

  const {
    partners
  } = data.allDataYaml.edges[0].node;

  return (
    <div
      id="block-partners"
      className={style.container}
    >
      <Container>
        <Row>
          <Col
            sm={12}
            md={12}
            lg={4}
          >
            <Title className={style.title}>
              <div className={style.outlineText}>
                Наши
              </div>
              {' партнеры'}
            </Title>
          </Col>

          <Col
            sm={12}
            md={12}
            lg={7}
            offset={{ lg: 1, md: 0 }}
          >
            <PartnersList
              partners={partners}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Partners;
